import { Error404 } from 'components/Error/404';
import MainLayout from 'layouts/MainLayout';

const Error404Page = () => {
  return (
    <MainLayout>
      <Error404 />
    </MainLayout>
  );
};

export default Error404Page;
